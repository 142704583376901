<template>
    <div class="register">
        <Pagenationtopimg />
        <Pagenationtop :pagenation_title='pagenation_title' path='register'/>
        <div class="main-container">
            <p>請詳閱會員條款，如您拒絕提供加入會員所需必要之資料，將可能導致無法享受完整服務或完全無法使用該項服務。</p>
            <div class="content">
                您好，歡迎您使用Exustar（浩捍）官網。<br>
                Exustar（浩捍）有限公司 （以下簡稱「Exustar（浩捍）」）將依據以下會員註冊服務條款（以下簡稱「本服務條款」）提供您Exustar（浩捍）有限公司網路交易服務（以下簡稱「本服務」）。為保障您的權益，請務必詳細閱讀本服務條款。<br>
                當您完成本會員註冊手續、或開始使用本服務時，即表示您已閱讀、瞭解並同意接受本服務條款之所有內容，且接受本服務現有與未來衍生的服務項目及內容。<br>
                Exustar（浩捍）有限公司有權於任何時間修改或變更本服務條款內容。修改後的服務條款內容將公佈於網站上，Exustar（浩捍）有限公司將不會個別通知會員，建議會員隨時注意該等修改或變更。會員於任何修改或變更後繼續使用本服務時，視為會員已閱讀、瞭解並同意接受該等修改或變更。若不同意上述的服務條款修訂或更新方式，或不接受本服務條款的其他任一約定，會員應立即停止使用本服務。<br>
                若會員為未滿二十歲之未成年人，應於會員家長（或監護人）閱讀、瞭解並同意本約定書之所有內容及其後修改變更後，方得註冊為會員，以使用或繼續使用本服務。當會員使用或繼續使用本服務時，即推定會員家長（或監護人）已閱讀、瞭解並同意接受本服務條款之所有內容及其後修改變更。會員及Exustar（浩捍）有限公司雙方同意使用本服務之所有內容，包括意思表示等，以電子文件作為表示方式。<br>
                Exustar（浩捍）有限公司將在您瀏覽網站、使用網站服務、加入會員、進行交易之過程中蒐集個人資料。為了確保個人資料、隱私及消費者權益保護，謹依個人資料保護法第8條、第9條規定告知以下事項：<br>
                ※蒐集之目的：<br>
                蒐集之目的在於「行銷」（法定特定目的項目代號○四○）、「非公務機關依法定義務所進行個人資料之蒐集處理及利用」（法定特定目的項目代號○六三）、「消費者、客戶管理與服務」（法定特定目的項目代號○九○）、「博物館、美術館、紀念館或其他公、私營造物業務」（法定特定目的項目代號一一五）、「場所進出安全管理」（法定特定目的項目代號一一六）、「網路購物及其他電子商務服務」（法定特定目的項目代號一四八）、「調查、統計與研究分析」（法定特定目的項目代號一五七）、「其他經營合於營業登記項目或組織章程所定之業務」（法定特定目的項目代號一八一）。<br>
                ※蒐集之個人資料類別：<br>
                Exustar（浩捍）於Exustar（浩捍）網站內蒐集的個人資料包括，<br>
                C001辨識個人者： 如會員或參觀者、活動參與者之姓名、地址、電話、電子郵件等資訊。<br>
                C002辨識財務者： 如信用卡或金融機構帳戶資訊。<br>
                C011個人描述：例如：性別、出生年月日、國籍等。<br>
                其他依主管機關公告之個人資料類別。<br>
                ※利用期間、地區、對象及方式：<br>
            </div>
            <div class="button-container">
                <router-link to="/">
                    取消
                </router-link>
                <router-link to="/creatmember">
                    我同意
                </router-link>
            </div>
        </div>
    </div>  
</template>

<script>
import Pagenationtopimg from "../components/pagenationimg/index.vue";
import Pagenationtop from '../components/pagenationtop/index.vue'
export default {
    data(){
        return{
            pagenation_title:[
                '加入會員'
            ]
        }
    },
    components:{
        Pagenationtopimg,
        Pagenationtop,
    },
    props:[
        'closeLogin'
    ],
    mounted(){
        this.closeLogin()
        this.$store.commit('closeOverlay')
    },

}
</script>

<style>

</style>